<template>
  <div class='py-2 lg:py-8 px-4 lg:px-0'>
    <div class='border-2 border-gray-100 px-4 lg:px-8 py-4 lg:py-8 rounded-lg shadow-md text-center font-light'>
      <p class='text-lg lg:text-xl opacity-50'>초록접수가 마감되었습니다.</p>
    </div>
    <div class='mt-4 lg:mt-12 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>초록접수 방법</h2>
      <div class='w-full lg:w-2/3 pl-6 lg:pl-0'>
        <h3 class='mt-2 text-lg lg:text-xl text-gray-900 relative'>
          1. 접수매체
        </h3>
        <p class='whitespace-pre-line mt-2 mb-8 text-gray-600 text-sm lg:text-base leading-6'>
          - 웹사이트를 통한 온라인 제출
        </p>
        <h3 class='mt-2 text-lg lg:text-xl text-gray-900 relative'>
          2. 접수부결
        </h3>
        <p class='whitespace-pre-line mt-2 mb-8 text-gray-600 text-sm lg:text-base leading-6'>
          - 초록 작성 요령을 따르지 않거나, 발표할 내용이 본 학회의 학술활동 기본 취지와 다를 경우에는 접수를 거부할 수 있습니다.
        </p>
        <h3 class='mt-2 text-lg lg:text-xl text-gray-900 relative'>
          3. 발표형식
        </h3>
        <p class='whitespace-pre-line mt-2 mb-8 text-gray-600 text-sm lg:text-base leading-6'>
          - 발표자와 교신저자의 연락처(이메일/휴대폰번호)는 반드시 기입하여 주시길 바라며 정상적으로 접수가 완료되면 발표자와 교신저자의 이메일로 ‘제출확인서’가 발송됩니다.
        </p>
        <h3 class='mt-2 text-lg lg:text-xl text-gray-900 relative'>
          4. 작성요령
        </h3>
        <p class='whitespace-pre-line mt-2 mb-8 text-gray-600 text-sm lg:text-base leading-6'>
          - 본문은 반드시 네 가지 항목(Background, methods, results, conclusion)으로 작성하시고 해당 항목에 따라 새로운 단락으로 작성을 해주시기 바랍니다.<br>*본문은 공란을 포함하여 총 1,000문자(2,000byte) 이내로 기재하여야 하며 총 <span class='font-bold' style='color: #513A76;'>300 단어 이내로</span> 기재해 주십시오.
        </p>
        <h3 class='mt-2 text-lg lg:text-xl text-gray-900 relative'>
          5. 발표
        </h3>
        <p class='whitespace-pre-line mt-2 mb-8 text-gray-600 text-sm lg:text-base leading-6'>
          - 초록은 영문과 국문 모두 가능하나, 발표는 한국어로 진행됩니다.
        </p>
      </div>
    </div>
    <div class='mt-4 lg:mt-12 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>유의 사항</h2>
      <div class='w-full lg:w-2/3 pl-6 lg:pl-0'>
        <ul style='list-style: inside;'
            class='whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
          <li class='mt-2 mb-8'>
            초록 접수가 완료되면 제출확인서가 이메일로 발송되며 이때 부여받은 접수번호는 <span class='font-bold' style='color: #08715B;'>초록 수정 시 필요하오니 접수번호를 수신하지 못한 경우에는 사무국으로 문의하여 주십시오.</span>
          </li>
          <li class='mt-2 mb-8'>
            초록 수정은 접수 마감일 전까지 수정 가능합니다.
          </li>
          <li class='mt-2 mb-8'>
            채택된 연제에 대해서는 개별 메일로 통보되며, 발표 및 준비요령은 추후 사무국에서 안내할 예정입니다.
          </li>
          <li class='mt-2 mb-8'>
            <span class='font-bold' style='color: #08715B;'>심사 결과에 따라 발표방식이 변경될 수 있습니다.</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AbstractRegistrationInformation',
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor'
    ]),
  },
}
</script>

<style type='scss'>
</style>